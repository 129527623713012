<template>
  <div class="catalog">
    <div class="filter-modal">
      <div class="catalog-modal__heading">
        <div class="title">Toutes les catégories</div>
        <button @click="$router.push('/')"><i class="material-icons notranslate">close</i></button>
      </div>
      <div class="filter-modal__content p-3">
        <div class="cat-category-list">
          <div class="p-1 item"
               v-if="c.image"
               v-for="(c,i) in showCategories"
               :key="i">
            <div class="square-ratio">
              <div class="category-item content"
                   @click="selectCategory(c)"
                   :style="'background-image: url('+c.image+')'">
                <div class="filter">
                  <strong>{{c.name}}</strong>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss">

.cat-category-list
{
  width: 100%;
  height: 130px;
  display: flex;
  flex-wrap: wrap;

  .item
  {
    flex: 0 0 33.33333%;
  }
  .category-item
  {
    border-radius: 10px;
    overflow: hidden;
    background-size: cover;
    background-position: center center;
    .filter
    {
      position: absolute;
      display: flex;
      justify-content: center;
      align-items: flex-end;
      width: 100%;
      height: 100%;
      background: linear-gradient(180deg, rgba(0,0,0,0.0) 60%, rgba(0,0,0,0.7));
      padding: 5px 10px;
      line-height: 1;

      strong
      {
        color: #fff;
        font-size: 0.75em;
        text-transform: uppercase;
      }
    }
  }
}

@media (max-width: 355px) {
  .cat-category-list {
    .item {
      flex: 0 0 50%;
    }
  }
}
</style>

<script>
import {mapGetters, mapActions} from 'vuex';
import '../scss/app_mobile.scss';

export default {
  data() {
    return {
      showCategories: []
    }
  },
  methods: {
    ...mapActions(['setSubCategoriesAction', 'setSubCategoriesAction', 'getFilteredMerchantsCatalogAction', 'initCatalogFilterAction', 'getCategoriesSlider']),
    selectCategory(cat){
      this.setSubCategoriesAction([cat.id]);
      this.$router.push('/?rel=fromCategoryPage')
    }
  },
  computed: {
    ...mapGetters(['categoriesSlider']),
  },
  async mounted() {
    if(this.categoriesSlider.length === 0){
      await this.initCatalogFilterAction();
      await this.getFilteredMerchantsCatalogAction();
      this.showCategories = await this.getCategoriesSlider();
    }
    else{
      this.showCategories = this.categoriesSlider;
    }
  }
}
</script>
