import { render, staticRenderFns } from "./Categories.vue?vue&type=template&id=6746c640"
import script from "./Categories.vue?vue&type=script&lang=js"
export * from "./Categories.vue?vue&type=script&lang=js"
import style0 from "./Categories.vue?vue&type=style&index=0&id=6746c640&prod&lang=scss"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports